
    /* eslint-disable */
    import {setPassiveTouchGestures} from '@polymer/polymer/lib/utils/settings.js';
    setPassiveTouchGestures(true);

    document.querySelector('landing-app').config = {
      "features": {
        "monitoring": true,
        "rbac": true,
        "orchestration": true,
        "insights": true,
        "billing": false,
        "tunnels": true,
        "ab": false,
        "r12ns": false,
        "signup_email": false,
        "signup_google": false,
        "signup_github": false,
        "signup_ms365": false,
        "signup_cilogon": false,
        "signin_email": false,
        "signin_google": false,
        "signin_github": false,
        "signin_ldap": false,
        "signin_ad": false,
        "signin_ms365": false,
        "signin_cilogon": false,
        "default_signin_method": "email",
        "signin_home": false,
        "landing_footer": true,
        "docs": "",
        "support": "",
        "currency": {
            "sign": "$",
            "rate": "1"
        }
      },
      "email": {
          "info": "info@mist.io",
          "support": "support@mist.io",
          "sales": "sales@mist.io"
      },
      "portalName": "mist.io",
      "stripeKey": "",
      "theme": ""
    };
    const repeater = document.querySelector('dom-repeat');
    const categories = [
      {
          "href": "/",
          "name": "home",
          "title": "Home"
      },
      {
          "href": "/product",
          "name": "product",
          "title": "Product"
      },
      {
          "hiddenFromMenu": 1,
          "href": "/orchestration",
          "name": "orchestration",
          "title": "Orchestration"
      },
      {
          "hiddenFromMenu": 1,
          "href": "/rbac",
          "name": "rbac",
          "title": "RBAC"
      },
      {
          "href": "/pricing",
          "name": "pricing",
          "title": "Pricing"
      },
      {
          "href": "/blog",
          "name": "blog",
          "title": "Blog"
      },
      {
          "href": "/about",
          "name": "about",
          "title": "About"
      },
      {
          "hiddenFromMenu": 1,
          "href": "/pledge",
          "name": "pledge",
          "title": "Pledge"
      },
      {
          "hiddenFromMenu": 1,
          "href": "/tos",
          "name": "tos",
          "title": "Terms of Service"
      },
      {
          "hiddenFromMenu": 1,
          "href": "/privacy-policy",
          "name": "privacy-policy",
          "title": "Privacy Policy"
      },
      {
          "hiddenFromMenu": 1,
          "href": "/orchestration",
          "name": "orchestration",
          "title": "Orchestration"
      },
      {
          "hiddenFromMenu": 1,
          "href": "/rbac",
          "name": "rbac",
          "title": "Role Based Access Control"
      }
  ];
    repeater.items = categories;
    document.querySelector('landing-app').categories = categories;
  
  